<template>
  <VCard>
    <VForm ref="form">
      <div class="px-8 py-8">
        <h2 class="section-title">
          Добавить ресторан
        </h2>
        <VRow class="mr-1">
          <VCol cols="6">
            <VAutocomplete
              v-model="selectedRestaurant"
              append-icon=""
              color="#E43539"
              hide-details="auto"
              hide-no-data
              hide-selected
              item-text="title"
              :items="restaurants"
              label="Введите название ресторана в Hermes"
              :loading="isLoading"
              :menu-props="menuProps"
              return-object
              :rules="rules"
              :search-input.sync="searched"
            />
          </VCol>
          <VCol class="d-flex align-center" cols="6">
            <VTextField
              v-model="partner.partnerId"
              color="#E43539"
              hide-details="auto"
              label="Введите ID в системе партнёра"
              :rules="rules"
            />
          </VCol>
          <VCol cols="12">
            <VTextarea
              v-model="partner.comment"
              auto-grow
              background-color="#FAFAFB"
              color="#E43539"
              placeholder="Напишите комментарии к ресторану (необязательно)"
              rows="1"
            />
          </VCol>
        </VRow>
      </div>
    </VForm>
    <div class="bottom-sticky d-flex justify-end px-8 pb-8">
      <VBtn
        class="subtitle-2 mr-2 br-8 font-weight-bold"
        color="#E43539"
        height="40"
        text
        @click="cancel"
      >
        Отменить
      </VBtn>
      <VBtn
        class="subtitle-2 white--text elevation-0 br-8 font-weight-bold"
        color="#E43539"
        height="40"
        @click="create"
      >
        Добавить
      </VBtn>
    </div>
  </VCard>
</template>

<script>
import { fetchRestaurants } from '@/api/api';

export default {
  emits: ['close', 'create'],
  data() {
    return {
      partner: {
        hermesId: 0,
        hermesName: '',
        partnerId: '',
        comment: '',
      },
      rules: [value => !!value || 'Обязательное поле'],
      selectedRestaurant: null,
      restaurants: [],
      isLoading: false,
      searched: null,
      menuProps: {
        maxHeight: 204,
        offsetY: true,
      },
    };
  },
  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.$emit('create', this.partner);
      }
    },
    cancel() {
      this.$emit('close');
    },
  },
  watch: {
    searched(text) {
      if (!text) {
        this.restaurants = [];
        this.selectedRestaurant = null;
        this.isLoading = false;
      } else {
        this.isLoading = true;
        fetchRestaurants(null, text).then((rests) => {
          this.restaurants = rests;
          this.isLoading = false;
        });
      }
    },
    selectedRestaurant: {
      handler(val) {
        if (val) {
          this.partner.hermesId = +val.pk;
          this.partner.hermesName = val.title;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
