<template>
  <div>
    <div class="pt-5 px-8 mt-0">
      <PageHeader
        create-btn-title="Зарегистрировать партнера"
        page-title="Партнеры"
        :search-items="partnersForSearch"
        search-placeholder="Поиск партнеров по названию"
        @click:create="createPartnerDialog = true"
        @search="getSearchPartnerList"
        @search-input="searchInput = $event"
      />
      <VDialog
        v-model="createPartnerDialog"
        content-class="br-12"
        max-width="640"
      >
        <PartnerCreate
          :key="key"
          @close="createPartnerDialog = false"
          @create="createPartner"
        />
      </VDialog>
      <VDialog
        v-model="editPartnerDialog"
        content-class="br-12"
        max-width="1248"
      >
        <PartnerUpdate
          :id="partnerID"
          :key="key"
          :password="partnerPassword"
          @close="closeEditPartnerDialog"
          @update="getPartners()"
        />
      </VDialog>
      <VDivider />
      <div class="d-flex my-4">
        <Dropdown
          :items="states"
          :selected="selectedStates"
          title="Все статусы"
          @selected="selectedStates = $event"
        />
      </div>
    </div>
    <UniversalTable
      ref="table"
      :headers="partnerHeaders()"
      :items="partners"
      :page="currentPage"
      @deactivate="deactivatePartner"
      @edit="editPartner"
      @update-page="currentPage = $event"
      @update-sort="sort"
    />
  </div>
</template>

<script>
import {
  getPartnersList,
  updateStatePartner,
  registerPartner,
} from '@/api/api';
import { partnerHeaders } from '@/conditions/tablesHeaders';
import PageHeader from '@/components/reusable/PageHeader.vue';
import PartnerUpdate from '@/components/PartnerUpdate.vue';
import PartnerCreate from '@/components/PartnerCreate.vue';
import UniversalTable from '@/components/reusable/UniversalTable.vue';
import Dropdown from '@/components/reusable/DropdownTable.vue';

const defaultStates = [{ title: 'Активно', value: 'active' }, { title: 'Отключено', value: 'inactive' }];

export default {
  components: {
    PageHeader, PartnerUpdate, UniversalTable, PartnerCreate, Dropdown,
  },
  data() {
    return {
      partners: [],
      defaultPartners: [],
      partnerHeaders,
      states: defaultStates,
      selectedStates: defaultStates,
      currentPage: 1,
      partnersForSearch: [],
      search: '',
      searchInput: null,
      editPartnerDialog: false,
      createPartnerDialog: false,
      partnerID: null,
      partnerPassword: null,
      key: 0,
      timeout: null,
    };
  },
  computed: {
    params() {
      const params = {
        page: this.currentPage,
        page_size: 20,

      };
      if (this.selectedStates.length === 1) {
        params.is_active = this.selectedStates[0].value === 'active';
      }
      return params;
    },
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    createPartner(data) {
      this.createPartnerDialog = false;
      registerPartner(data).then((res) => {
        this.partnerPassword = res.password;
        this.editPartner({ ...data, ...res });
      });
    },
    editPartner(partner) {
      this.partnerID = partner.id;
      this.key += 1;
      this.editPartnerDialog = true;
    },
    closeEditPartnerDialog() {
      this.partnerID = null;
      this.partnerPassword = null;
      this.editPartnerDialog = false;
    },
    getPartners() {
      this.$refs.table.loading = true;
      getPartnersList(this.params).then((response) => {
        this.partners = response.result;
        this.defaultPartners = JSON.parse(JSON.stringify(response.result));
        this.currentPage = response.current_page;
        this.$refs.table.totalPage = response.total_page;
        this.$refs.table.totalCount = response.total_count;
      }).finally(() => {
        this.$refs.table.loading = false;
      });
    },
    getPartnersForSearch(search) {
      const params = { ...JSON.parse(JSON.stringify(this.params)) };
      params.search = search;
      params.page = 1;
      getPartnersList(params).then((response) => {
        this.partnersForSearch = response.result;
      });
    },
    getSearchPartnerList(search) {
      if (search) {
        this.partners = [];
        this.partners.push(search);
        this.$refs.table.totalPage = 1;
        this.$refs.table.totalCount = 1;
      }
    },
    deactivatePartner(partner) {
      // eslint-disable-next-line no-param-reassign
      partner.state = 'inactive';
      updateStatePartner(partner).then(() => {
        this.getPartners(this.currentPage);
      });
    },
    sort(selectedSort) {
      const key = Object.keys(selectedSort)[0];
      if (key && key.length) {
        const valueType = typeof this.partners[0][key];
        const sortingOptions = {
          string: {
            asc: (a, b) => a[key].localeCompare(b[key]),
            desc: (a, b) => b[key].localeCompare(a[key]),
          },
          number: {
            asc: (a, b) => {
              if (a[key] < b[key]) {
                return -1;
              }
              if (a[key] > b[key]) {
                return 1;
              }
              return 0;
            },
            desc: (a, b) => {
              if (b[key] < a[key]) {
                return -1;
              }
              if (b[key] > a[key]) {
                return 1;
              }
              return 0;
            },
          },
        };
        this.partners.sort(sortingOptions[valueType][selectedSort[key]]);
      } else {
        this.partners = [...JSON.parse(JSON.stringify(this.defaultPartners))];
      }
    },
  },
  watch: {
    params: {
      deep: true,
      handler() {
        this.getPartners();
      },
    },
    currentPage(val) {
      this.getPartners(val);
    },
    searchInput(val) {
      if (val != null) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getPartnersForSearch(val);
        }, 500);
      } else {
        this.search = null;
        this.partnersForSearch = [];
        this.getPartners();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
