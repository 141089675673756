<template>
  <VCard>
    <VForm ref="form">
      <div class="px-8 py-8">
        <h2 class="section-title">
          Вы уверены что хотите обновить пароль?
        </h2>
        <div class="partner-warn mt-6 d-flex justify-start">
          <VImg
            class="my-5 mx-4"
            height="24"
            :src="warning"
            width="24"
          />
          <div class="mt-4 partner-warn__text">
            <h3>Внимание</h3>
            <p>
              Данное действие необратимо и приведёт
              к отключению партнёра от интеграции
            </p>
          </div>
        </div>
      </div>
    </VForm>
    <div class="bottom-sticky d-flex justify-end px-8 pb-8">
      <VBtn
        class="subtitle-2 mr-2 br-8 font-weight-bold"
        color="#E43539"
        height="40"
        text
        @click="cancel"
      >
        Отменить
      </VBtn>
      <VBtn
        class="subtitle-2 white--text elevation-0 br-8 font-weight-bold"
        color="#E43539"
        height="40"
        @click="reset"
      >
        Обновить
      </VBtn>
    </div>
  </VCard>
</template>

<script>
import warning from '@/assets/icons/warning.svg';

export default {
  emits: ['reset', 'close'],
  data() {
    return {
      warning,
    };
  },
  methods: {
    reset() {
      this.$emit('reset');
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

.partner-warn {
  background: #FFEEEE;
  border-radius: 8px;
  width: 100%;
  height: 116px;
  border-left: 4px solid #E43539;
  &__text {
    color: #E43539;
    p {
      font-weight: 550;
      font-size: 16px;
      line-height: 22px;
      margin-top: 5px;
    }
    h3 {
      font-size: 20px;
      font-weight: 700;
    }
  }
}
</style>
