<template>
  <VCard>
    <VForm ref="form">
      <div class="px-8 py-8">
        <h2 class="section-title">
          Зарегистрировать партнёра
        </h2>
        <VRow class="mr-1">
          <VCol cols="6">
            <VTextField
              v-model="title"
              color="#E43539"
              hide-details="auto"
              label="Введите название партнёра"
              :rules="rules"
            />
          </VCol>
          <VCol class="d-flex align-center pa-0" cols="6">
            <VTextField
              v-model="login"
              color="#E43539"
              hide-details="auto"
              label="Введите логин партнёра"
              :rules="rules"
              @keypress="isLetter"
            />
          </VCol>
        </VRow>
      </div>
    </VForm>
    <div class="bottom-sticky d-flex justify-end px-8 pb-8">
      <VBtn
        class="subtitle-2 mr-2 br-8 font-weight-bold"
        color="#E43539"
        height="40"
        text
        @click="cancel"
      >
        Отменить
      </VBtn>
      <VBtn
        class="subtitle-2 white--text elevation-0 br-8 font-weight-bold"
        color="#E43539"
        height="40"
        @click="create"
      >
        Добавить
      </VBtn>
    </div>
  </VCard>
</template>

<script>
export default {
  emits: ['create', 'close'],
  data() {
    return {
      title: '',
      login: '',
      rules: [(value) => !!value || 'Обязательное поле'],
    };
  },
  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.$emit('create', { title: this.title, login: this.login });
      }
    },
    cancel() {
      this.$emit('close');
    },
    isLetter(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) {
        return true;
      }
      e.preventDefault();
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
