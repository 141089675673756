import { render, staticRenderFns } from "./PartnerTableRestaurants.vue?vue&type=template&id=04a52cae&scoped=true"
import script from "./PartnerTableRestaurants.vue?vue&type=script&lang=js"
export * from "./PartnerTableRestaurants.vue?vue&type=script&lang=js"
import style0 from "./PartnerTableRestaurants.vue?vue&type=style&index=0&id=04a52cae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a52cae",
  null
  
)

export default component.exports