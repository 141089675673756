<template>
  <div>
    <VSimpleTable class="table">
      <thead>
        <tr v-if="headers.length" class="titles-thead">
          <th
            v-for="(row, rowIndex) in headers"
            :key="rowIndex"
            :class="row.classList"
            :style="row.styles || {}"
          >
            <span>{{ row.text }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, itemIndex) in restaurants"
          :key="itemIndex"
          class="tr"
        >
          <td class="py-3 text-start">
            <span>
              {{ item.hermesId }}
            </span>
          </td>
          <td class="text-start">
            <span>
              {{ item.hermesName }}
            </span>
          </td>
          <td class="text-start">
            <span>
              {{ item.comment }}
            </span>
          </td>
          <td class="text-start">
            <span>
              {{ item.partnerId }}
            </span>
          </td>
          <td>
            <div class="d-flex align-start pl-0 pr-0">
              <VMenu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <VBtn
                    v-bind="attrs"
                    elevation="0"
                    hide-details
                    max-height="35"
                    max-width="35"
                    text
                    v-on="on"
                  >
                    <VImg class="" :src="dotsBtn" />
                  </VBtn>
                </template>
                <VList class="action">
                  <VListItem
                    v-for="(el, elementIndex) in actions"
                    :key="elementIndex"
                    class="action__item"
                    dense
                    hide-details
                    @change="deleteRestaurant(item)"
                  >
                    <VListItemTitle class="action__item__title">
                      {{ el.title }}
                    </VListItemTitle>
                  </VListItem>
                </VList>
              </VMenu>
            </div>
          </td>
        </tr>
      </tbody>
    </VSimpleTable>
  </div>
</template>

<script>
import { partnerRestaurantsHeader } from '@/conditions/tablesHeaders';
import dotsBtn from '@/assets/icons/dots-btn.svg';

export default {
  props: {
    restaurants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['remove'],
  data() {
    return {
      dotsBtn,
      headers: partnerRestaurantsHeader,
      actions: [{ title: 'Удалить', value: 'remove' }],
    };
  },
  methods: {
    deleteRestaurant(item) {
      this.$emit('remove', item.hermesId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.tr>* {
  font-size: 14px !important;
}

::v-deep {
  .action {
    padding: 0px;
    border-radius: 8px!important;
    height: fit-content;
    &__item {
      :hover {
        cursor: pointer;
      }
      &__title {
        color: #E43539!important;
        line-height: 18px;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
  .table {

    thead {
      .theme--light.v-text-field {
        background: #7F89A4;
        color: white;
        font-size: 10px;
      }
      .v-text-field--dense, .v-list--dense .v-list-item {
        min-height: 30px;
      }
      .v-icon.v-icon--dense {
        font-size: 15px;
      }
      .titles-thead {
        background-color: #FAFAFB !important;
        th {
          span {
            color: #1F1F1F !important;
            font-weight: 500;
          }
        }
      }
      .tr {
        background-color: #3E3E3E !important;
        color: white;
      }
      .filter-head {
        margin: 0px;
        padding: 0px;
        width: 60%;
      }
      .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
        min-height: 30px !important;
      }
      .v-input__slot {
        background-color: #5B5B5B !important;
        color: white !important;
      }
    }
  }
}
</style>
