<template>
  <VCard v-if="partnerToEdit">
    <div class="top-sticky white">
      <div class="d-flex justify-space-between align-center pa-3">
        <Breadcrumbs :items="breadcrumbs" />
        <VBtn icon @click="closePartnerDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VDivider />
    </div>
    <VForm ref="form">
      <VRow class="mx-0">
        <VCol class="pt-6 pb-12 pl-6 pr-10 mb-2">
          <WideTextField
            label="Название партнера"
            :value="partnerToEdit.title"
            @input="partnerToEdit.title = $event"
          />
          <div class="">
            <VRow class="mr-1">
              <VCol cols="4">
                <VTextField
                  v-model="partnerToEdit.login"
                  color="#E43539"
                  hide-details="auto"
                  label="Логин"
                  :rules="rules"
                />
              </VCol>
              <VCol class="d-flex align-center pa-0" cols="4">
                <VTextField
                  ref="password"
                  v-model="partnerToEdit.password"
                  color="#E43539"
                  hide-details="auto"
                  label="Пароль"
                  readonly
                />
              </VCol>
              <VCol class="align-center pt-5" cols="4">
                <VBtn
                  class="subtitle-2 mr-2 br-8 font-weight-bold mt-1"
                  color="#E43539"
                  height="40"
                  text
                  @click="actionPassword"
                >
                  {{
                    password || partnerToEdit.password !== '・・・・・・・・'
                      ? 'Скопировать пароль'
                      : 'Обновить пароль'
                  }}
                </VBtn>
                <VDialog
                  v-model="resetPartnerPasswordDialog"
                  content-class="br-12"
                  max-width="640"
                >
                  <PartnerWarningResetPass
                    @close="resetPartnerPasswordDialog = false"
                    @reset="resetPassword"
                  />
                </VDialog>
              </VCol>
            </VRow>
            <h2 class="section-title mt-7">
              URL для заказов
            </h2>
            <VRow class="mr-1 mt-0 pt-0">
              <VCol cols="6">
                <VTextField
                  v-model="partnerToEdit.createUrl"
                  color="#E43539"
                  hide-details="auto"
                  label="Введите URL для создания заказов"
                />
              </VCol>
              <VCol class="d-flex align-center pa-0" cols="6">
                <VTextField
                  v-model="partnerToEdit.updateUrl"
                  color="#E43539"
                  hide-details="auto"
                  label="Введите URL для обновления статусов заказа"
                />
              </VCol>
            </VRow>
            <h2 class="section-title mt-7 mb-2">
              Рестораны
            </h2>
            <div class="">
              <PartnerTableRestaurants
                v-if="restaurants.length"
                :restaurants="restaurants"
                @remove="removeRestaurant"
              />
              <PrevNextButtons
                :current-page="currentPage"
                :pages-count="totalPagesCount"
                @next-page="changePage(1)"
                @previous-page="changePage(-1)"
              />
              <VDivider v-if="restaurants.length" />
              <VBtn
                class="subtitle-2 pl-0 mr-2 br-8 font-weight-bold"
                color="#E43539"
                height="40"
                text
                @click="openAddPartnerRestaurantDialog"
              >
                <VIcon class="mr-1">
                  mdi-plus
                </VIcon> Добавить ресторан
              </VBtn>
              <VDialog
                v-model="addPartnerRestaurantDialog"
                content-class="br-12"
                max-width="640"
              >
                <PartnerAddRestaurant
                  :key="key"
                  @close="addPartnerRestaurantDialog = false"
                  @create="addRestaurant"
                />
              </VDialog>
            </div>
          </div>
        </VCol>
        <VDivider vertical />
        <VCol class="pa-4 d-flex flex-column" cols="3">
          <div>
            <div class="font-weight-bold mb-2">
              Статус
            </div>
            <VMenu
              content-class="elevation-1"
              :disabled="!partnerToEdit.id"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <VBtn
                  v-bind="attrs"
                  block
                  class="elevation-0 d-flex justify-space-between pl-1 pr-2"
                  :color="stateParams[partnerToEdit.state].color"
                  :style="{'color': stateParams[partnerToEdit.state].textColor}"
                  v-on="on"
                >
                  <div class="subtitle-1">
                    <VIcon>mdi-circle-small</VIcon>
                    {{ stateParams[partnerToEdit.state].title }}
                  </div>
                  <VIcon v-if="partnerToEdit.id" size="18">
                    mdi-chevron-down
                  </VIcon>
                </VBtn>
              </template>
              <VList>
                <VListItemGroup v-model="partnerToEdit.state">
                  <VListItem
                    v-for="(state, index) in states"
                    v-show="state !== partnerToEdit.state"
                    :key="index"
                    dense
                    :value="state"
                    @change="updateState(state)"
                  >
                    <VListItemTitle class="subtitle-1 pl-6" v-text="stateParams[state].title" />
                  </VListItem>
                </VListItemGroup>
              </VList>
            </VMenu>
          </div>
          <VDivider class="my-1" />
        </VCol>
      </VRow>
    </VForm>
    <div class="bottom-sticky">
      <VDivider />
      <div
        class="d-flex align-center grey lighten-5 px-4 py-3 justify-space-between"
      >
        <div v-if="partnerToEdit.id" class="text--secondary">
          <span v-if="partnerToEdit.updatedDt">
            Изменено: {{ formatDate(partnerToEdit.updatedDt) }}
          </span>
          <span v-else> Создано: {{ formatDate(partnerToEdit.createdDt) }} </span>
        </div>
        <div class="subtitle-2">
          <VBtn
            class="subtitle-2 white--text elevation-0 br-8 font-weight-bold"
            color="#E43539"
            height="40"
            @click="update"
          >
            Сохранить
          </VBtn>
        </div>
      </div>
    </div>
  </VCard>
</template>

<script>
import {
  updateStatePartner,
  updatePartner,
  resetPasswordPartner,
  getInfoPartner,
  getPartnerRestaurants,
  addPartnerRestaurant,
  removePartnerRestaurant,
} from '@/api/api';
import Breadcrumbs from '@/components/reusable/Breadcrumbs.vue';
import WideTextField from '@/components/reusable/WideTextField.vue';
import { partnerStates } from '@/conditions/states';
import { formatDateWithTime } from '@/helpers/date';
import PartnerAddRestaurant from '@/components/PartnerAddRestaurant.vue';
import PartnerTableRestaurants from '@/components/PartnerTableRestaurants.vue';
import PartnerWarningResetPass from '@/components/PartnerWarningResetPass.vue';
import PrevNextButtons from '@/components/reusable/PrevNextButtons.vue';

export default {
  components: {
    PrevNextButtons,
    WideTextField,
    Breadcrumbs,
    PartnerAddRestaurant,
    PartnerTableRestaurants,
    PartnerWarningResetPass,
  },
  props: {
    id: {
      type: Number,
    },
    password: {
      type: String,
    },
  },
  emits: ['update', 'close'],
  data() {
    return {
      partnerToEdit: null,
      stateParams: partnerStates,
      states: ['active', 'inactive'],
      formatDate: formatDateWithTime,
      rules: [(value) => !!value || 'Обязательное поле'],
      key: 0,
      addPartnerRestaurantDialog: false,
      resetPartnerPasswordDialog: false,
      restaurants: [],
      defaultPassword: '・・・・・・・・',
      currentPage: 1,
      totalPagesCount: 1,
    };
  },
  created() {
    this.getPartnerDetails();
    this.getPartnerRestaurants();
  },
  computed: {
    breadcrumbs() {
      return [
        { text: 'Партнеры', disabled: false, href: '/integration' },
        {
          text: this.id && this.password ? 'Добавить партнера' : 'Редактировать партнера',
          disabled: true,
          href: '',
        },
      ];
    },
  },
  methods: {
    getPartnerDetails() {
      getInfoPartner(this.id).then((res) => {
        this.partnerToEdit = res;
        this.partnerToEdit.password = this.password || this.defaultPassword;
      });
    },
    resetPassword() {
      resetPasswordPartner(this.partnerToEdit.id).then((res) => {
        this.partnerToEdit.password = res.password;
        this.resetPartnerPasswordDialog = false;
      });
    },
    actionPassword() {
      if (this.password || this.partnerToEdit.password !== this.defaultPassword) {
        const textToCopy = this.$refs.password.$el.querySelector('input');
        textToCopy.select();
        document.execCommand('copy');
      } else {
        this.resetPartnerPasswordDialog = true;
      }
    },
    update() {
      if (this.$refs.form.validate() && this.partnerToEdit.id) {
        updatePartner(this.partnerToEdit.id, this.partnerToEdit)
          .then(() => {
            this.$emit('update');
            this.closePartnerDialog();
          });
      }
    },
    changePage(incOrDecr) {
      this.currentPage += incOrDecr;
      this.getPartnerRestaurants();
    },
    updateState(val) {
      updateStatePartner(this.partnerToEdit.id, { is_active: val === 'active' }).then(() => {
        this.getPartnerDetails();
        this.$emit('update');
      });
    },
    getPartnerRestaurants() {
      getPartnerRestaurants(this.id, this.currentPage).then((res) => {
        this.restaurants = res.result;
        this.totalPagesCount = res.total_page;
      });
    },
    addRestaurant(payload) {
      addPartnerRestaurant(this.id, payload).then(() => {
        this.addPartnerRestaurantDialog = false;
        this.getPartnerRestaurants();
      });
    },
    removeRestaurant(restId) {
      removePartnerRestaurant(this.id, restId).then(() => {
        this.getPartnerRestaurants();
      });
    },
    openAddPartnerRestaurantDialog() {
      this.addPartnerRestaurantDialog = true;
      this.key += 1;
    },
    closePartnerDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
